import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import scanningPen from '../views/scanningPen.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/pczx/product1',
    name: 'homeView',
    component: () => import('../views/homeView.vue')
  },
  {
    path: '/',
    name: 'homeView',
    component: () => import('../views/homeView.vue')
  },
  {
    path: '/pczx/product2',
    name: 'scanningPen',
    component: () => import('../views/scanningPen.vue')
  }
]
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/scanningPen',
//     name: 'scanningPen',
//     component: scanningPen
//   }
// ]

const router = new VueRouter({
  routes
})

export default router
